@import '../../components/variables.scss';
.cv15-board {
    width: 21cm;
    height: fit-content !important;
    font-family: $font;
    word-break: break-word;
    // cv-content
    .cv15-content {
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 14px;
        box-sizing: border-box;

        //left
        .cv15-left-side {
            width: 340px;
            background-color: #292C2B;
            min-height: 28.6cm;

            padding: 20px;

            .cv15-left-side-head {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                .cv15-image {
                    width: 150px;
                    height: 150px;
                    border-radius: 20%;
                    border: 3px solid white;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .cv15-left-side-body {
                margin-top: 30px;
                .cv15-left-title {
                    font-weight: 600;
                    // to upper case
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 1.2em;

                    color: white;
                }
                .cv15-summary {
                    font-size: 0.9em;
                    color: white;
                }
                .cv15-skills {
                    margin-bottom: 10px;
                    .cv15-skills-item {
                        p{
                            font-size: 0.9em;
                            color: white;
                        }
                        .cv15-skills-item-bar {
                            border: 1px solid white;
                            border-radius: 5px;
                            height: 5px;
                            margin-top: 5px;

                            .cv15-skills-item-bar-inner {
                                background-color: white;
                                height: 100%;
                                border-radius: 5px;
                                transition:  all 0.5s ease;
                            }
                        }
                    }
                }
                .cv15-languages{
                 
                    .cv15-languages-item{
                        display: flex;
                        justify-content: space-between;
                        p{
                            font-size: 0.9em;
                            color: white;
                           
                          
                        }
                        // the second p
                        p:nth-child(2){
                           
                            // to uper case
                            text-transform: uppercase;
                        }
                        
                    }
                }
            }
        }
        //right
        .cv15-right-side {
            width: 100%;
            .cv15-right-side-head{
                display: flex;
                padding: 20px 10px;
                justify-content: space-between;
                background-color: #E7EAF5;
                align-items: center;
                color: #232120;
                .cv15-right-side-head-left{
                    p,h1{
                        margin: 0px;
                    }
                    h1{
                        text-transform: uppercase;
                    }

                }
                .cv15-right-side-head-right{
                    .cv15-contact-info{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 20px 0px;
                        
                        .cv15-contact-info-item{
                            display: flex;
                            font-size: 0.8em;
                            align-items: center;
                            margin-top: 10px;
                            .cv15-contact-icon{
                        
                                margin-right: 10px;
                                font-size: 0.8em;
                            }
                            p{width: 150px;
                                margin: 0px;
                                font-size: 0.8em;

                            }
                        }
                    }
                }

            }
            .cv15-right-side-body{
                padding: 20px;
                .cv15-right-title{
                    display: flex;
                    align-items: center;
                    span{
                        font-weight: 600;
                        // to upper case
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-size: 1.4em;
                    }
                .cv15-right-title-icon{

                    margin-right: 10px;
                    fill: #6c999a;
                    font-size: 1.8em;
                }
               } 
               .cv15-work-history{
                width: 100%;
                margin-bottom: 20px;
                .cv15-work-history-item{
                    width: 100%;
                    display: flex;
                    margin-top: 20px;
                    .cv15-work-history-item-left{
                        width: 20%;
                        font-size: 0.8em;
                        margin-top: 8px;
                     
                        p{
                            margin: 0px;
                        }
                        
                    }
                    .cv15-work-history-item-right{
                        width: 100%;
                        padding-left: 10px;
                        h3{
                            margin: 5px 0px;
                          
                        }
                        p{
                            margin: 0px;
                            font-size: 0.8em;
                        }
                    }
                }
               }
            }
        }
    }
}
