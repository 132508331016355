@import '../../components/variables.scss';
.cv13-board {
    width: 21cm;
    height: fit-content !important;
    font-family: $font;
    word-break: break-word;
    // cv-content
    .cv13-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        box-sizing: border-box;
        

        .cv13-header{
         // background-color: #0D3A71;
         border-bottom: 1px solid rgb(213, 213, 213);
          padding: 20px 20px;
          h1 {
            font-size: 3em;
            font-weight: 600;
            margin:0px;
            color: #3498DB;
          }
          p{
            margin:0px;
            font-size: 1.5em;
          }
        }
        .cv13-content-body {
          padding: 0px  0px 0px 20px;
          display: flex;
            .cv13-left {
                 width: 70%;
                .cv13-summary {
                  font-size: 0.8em;
                }
               
                /// employments
                .cv13-employments{
                  .cv13-employment-item{
                    display: flex;
                    margin-bottom: 20px;
                    .cv13-employment-item-left{
                      width: 15%;
                      font-size: 0.7em;
                      p{
                        margin: 10px 0px;
                      }

                    }
                    .cv13-employment-item-right{
                      width: 80%;
                      h3{
                        font-size: 1em;
                        margin: 10px 0px;

                      }
                      p{
                        font-size: 0.8em;
                        margin: 0px;
                        margin-top: 5px;
                      }
                    }
                  }
                }
               
            }

            .cv13-right {
              box-sizing: border-box;
              width: 30%;
              flex-grow: 1;
              padding-left: 10px;
              padding-top: 10px;
              border-left: 1px solid rgb(213, 213, 213);
              .cv13-info{
                margin-bottom: 20px;
                padding: 10px;

                color: #5E5E5E;
                .cv13-info-title{
                  font-weight: 600;
                  margin-bottom: 4px;
                }
                .cv13-info-content{
                  font-size: 0.9em;
                  margin: 0px;
                }
              
               
              }
              .cv13-skills{
                .cv13-skill-item{
                  font-size: 0.9em;
                  margin: 0px;
                  color: #5E5E5E;
                  padding: 10px;


                }
              }
              .cv13-languages{
                padding: 10px;

                .cv13-languages-item{
                  margin: 10px 0px;
                  font-size: 0.9em;
                  display: flex;
                  justify-content: space-between;
                  color: #5E5E5E;
                  p{
                    margin: 0px;
                  }
                  p:nth-child(2){
                    font-weight: 600;
                  }
                }
              }
            }
        }
    }
}


.cv13-section-title {
  border-bottom: 1px solid rgb(213, 213, 213);
  h2{
    margin: 0px 0px 10px 0px;
    margin-left: 0px;

  }
}